import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AbstractValueAccessor, makeProvider } from '../abstract-value-accessor';

@Component({
  selector: 'boolean-with-follow-up',
  templateUrl: './boolean-with-follow-up.component.html',
  providers: [makeProvider(() => BooleanWithFollowUpComponent)],
  viewProviders: [{ provide: ControlContainer, deps: [NgForm], useExisting: NgForm }],
  styleUrls: ['./boolean-with-follow-up.component.scss']
})
export class BooleanWithFollowUpComponent extends AbstractValueAccessor {

  @Input() name: string;
  @Input() label: string;
  @Input() followUpLabel: string = 'Please provide a brief explanation';
  @Input() required: boolean = false;
  private supressNextOnValueSet: boolean;
  protected showQuestion: boolean;
  protected followUpValue: string;

  // onValueSet is used to initialize the form state, afterwards we don't need it to do anything as the ngModel bindings are driving things
  protected onValueSet(value: any): void {
    if (this.supressNextOnValueSet || value === undefined) {
      return;
    }

    if (value == null) {
      this.showQuestion = undefined;
      this.followUpValue = undefined;
    } else if (value === '0') {
      this.showQuestion = false;
    } else {
      this.showQuestion = true;
      this.followUpValue = value;
    }
  }

  protected handleInputChange() {
    this.supressNextOnValueSet = true;
    this.value = this.buildInputFieldValue();
    this.supressNextOnValueSet = false;
  }

  private buildInputFieldValue(): string {
    // showQuestion should have 3 states true, false, or undefined hence the explicit ""== false/true"
    if (this.showQuestion == false) {
      return '0';
    } else if (this.showQuestion == true) {
      return this.followUpValue;
    }
    return null;
  }
}
