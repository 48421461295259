<div class="modal-header">Reauthenticate to end impersonation</div>
<div class="modal-body">
  Clicking &quot;End Impersonation&quot; will end your impersonation session. You will be logged out of MyRPS and
  logged back in
  as yourself. Please note that if you are off AJG network, you will have to enter <b>your</b> username and password
  again.
</div>
<div class="modal-footer d-flex align-items-end">
  <button id="dev-login-close" class="btn btn-default" (click)="cancel()">Cancel</button>
  <button id="dev-login-ok" class="btn btn-action" (click)="end()">End Impersonation</button>
</div>