import { Component } from '@angular/core';

@Component({
  selector: 'page-footer-social-media',
  templateUrl: './page-footer-social-media.component.html',
  styleUrls: ['./page-footer-social-media.component.scss']
})
export class PageFooterSocialMediaComponent {
  socialMediaLinks: any;

  constructor() { }
}
