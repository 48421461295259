import { Component, Input } from '@angular/core';
import { NotificationService, UserProfile } from '@app/core';
import { environment } from '@env';
import { CredentialModalComponent } from './credential-modal/credential-modal.component';
import { SsoModalComponent } from './sso-modal/sso-modal.component';

@Component({
  selector: 'impersonation-header',
  templateUrl: './impersonation-header.component.html',
  styleUrls: ['./impersonation-header.component.scss']
})
export class ImpersonationHeaderComponent {

  @Input() profile: UserProfile;

  constructor(private readonly notificationSvc: NotificationService) {
  }

  endImpersonation() {
    let template: any;
    if (environment.endImpersonationWithCiam) {
      template = SsoModalComponent;
    } else {
      template = CredentialModalComponent;
    }
    this.notificationSvc.showModalTemplate(template);
  }
}
