<div class="modal-header">Login to end impersonation</div>
<div class="modal-body">
  <div>
    In order to end impersonation and return to your workspace, you will need to log out with <b>your</b> credentials.
  </div>
  <div class="faux-material-form-group mt-0 pt-0">
    <label class="form-label">Username</label>
    <input type="text" name="dev-username" id="dev-username" [(ngModel)]="username" class="form-control" />
  </div>
  <div class="faux-material-form-group">
    <label class="form-label">Password</label>
    <input type="password" name="dev-password" id="dev-password" [(ngModel)]="password" class="form-control" />
  </div>
</div>
<div class="modal-footer d-flex align-items-end">
  <button id="dev-login-close" class="btn btn-default" (click)="cancel()">Cancel</button>
  <button id="dev-login-ok" class="btn btn-action" (click)="endImpersonation()">End Impersonation</button>
</div>