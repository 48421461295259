<nav class="rps-footer-social" id="rps-social-media">
  <a href="https://www.facebook.com/RPSINS/" target="_blank" class="p-0 me-3">
    <i class="fab fa-facebook-f"></i>
  </a>
  <a href="https://www.linkedin.com/company/risk-placement-services-inc/" target="_blank" class="p-0 me-3">
    <i class="fab fa-linkedin-in"></i>
  </a>
  <a href="https://twitter.com/rpsins" target="_blank" class="p-0 me-3">
    <i class="fab fa-twitter"></i>
  </a>
  <a href="https://www.youtube.com/channel/UCIARNqTE_QmdKblhJUJf0zQ/featured" target="_blank" class="p-0 me-3">
    <i class="fab fa-youtube"></i>
  </a>
  <a href="https://www.instagram.com/rps_ins/" target="_blank" class="p-0 me-0">
    <i class="fab fa-instagram"></i>
  </a>
</nav>