import { Component } from '@angular/core';

@Component({
  selector: 'layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent {

  constructor() { }
}
