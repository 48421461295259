import { Component } from '@angular/core';

@Component({
  selector: 'page-footer-logo',
  templateUrl: './page-footer-logo.component.html',
  styleUrls: ['./page-footer-logo.component.scss']
})
export class PageFooterLogoComponent {

  constructor() { }
}
