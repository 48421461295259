import './lib-extensions'; // global helpers

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import {
  AbbreviateNumberPipe,
  AddressFieldComponent,
  AuthGuard,
  AuthHttpInterceptor,
  AutocompleteComponent,
  AutocompleteDropdownComboComponent,
  AutofocusDirective,
  BooleanWithFollowUpComponent,
  BreakOnSlashPipe,
  CheckboxControlComponent,
  CompleteAddressValidator,
  DatePickerComponent,
  DebounceDirective,
  DefaultValueDirective,
  DirectoryService,
  DisplayComponent,
  EmailValidator,
  FilterListComponent,
  GeoService,
  GetGroupResourcePipe,
  GetResourcePipe,
  GooglePlacesApiFacadeService,
  HasbroChoiceComponent,
  InputValidationComponent,
  IntegerDirective,
  LowerCaseUrlSerializer,
  ManualComponent,
  MaxLengthValidator,
  MaxValueValidator,
  MinValueValidator,
  ModalSpinnerComponent,
  ModalSpinnerService,
  MultiCheckSelectorComponent,
  NotificationErrorModalComponent,
  NotificationMarkdownModalComponent,
  NotificationMessageModalComponent,
  NotificationService,
  ODataQueryBuilderService,
  PanelArrowComponent,
  PhoneNumberPipe,
  PhoneNumberValidator,
  ProfileService,
  RemoveNullFromArrayPipe,
  ResourceService,
  RouterStateService,
  SaneDateParserFormatter,
  StreetNumberValidator,
  SuppressReloadService,
  TextHighlightPipe,
  TooltipComponent,
  WindowService,
  YesNoPipe
} from '.';
import { AimUserProfileService } from './aim/aim-user-profile.service';
import { AimService } from './aim/aim.service';
import { RetailerProfileService } from './aim/retailer-profile.service';
import { DownloadService } from './download/download.service';
import { EnvironmentService } from './environment.service';
import { DateRangePickerComponent } from './forms/date-range-picker/date-range-picker.component';
import { DisableNumberScrollDirective } from './forms/disable-number-scroll.directive';
import { DropdownComponent } from './forms/dropdown/dropdown.component';
import { FormErrorsComponent } from './forms/form-errors/form-errors.component';
import { MultiCheckSearchSelectorComponent } from './forms/multi-check-search-selector/multi-check-search-selector.component';
import { PaginatorComponent } from './forms/paginator/paginator.component';
import { ReactiveFormValidationService } from './forms/reactive-form-validation.service';
import { SearchFilterComponent } from './forms/search-filter/search-filter.component';
import { SubmissionsListBaseComponent } from './forms/submissions/submissions-list-base.component';
import { TableSortDirective } from './forms/submissions/table-sort.directive';
import { TrimDirective } from './forms/trim.directive';
import { EinSsnPipe, HumanReadablePipe } from './pipes';
import { AccountingPipe } from './pipes/accounting.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { InsureZoneService, PlatformService } from './platform-service';
import { UrlBasedTitleStrategy } from './routing/url-based-title.service';
import { SessionService } from './storage/session.service';
import { UserService } from './user/user.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    MarkdownModule
  ],
  declarations: [
    AbbreviateNumberPipe,
    AddressFieldComponent,
    AutocompleteComponent,
    AutofocusDirective,
    BreakOnSlashPipe,
    CheckboxControlComponent,
    CompleteAddressValidator,
    DatePickerComponent,
    DebounceDirective,
    DefaultValueDirective,
    DisplayComponent,
    EinSsnPipe,
    EmailValidator,
    FilterListComponent,
    GetGroupResourcePipe,
    GetResourcePipe,
    HasbroChoiceComponent,
    InputValidationComponent,
    IntegerDirective,
    ManualComponent,
    MaxValueValidator,
    MinValueValidator,
    ModalSpinnerComponent,
    MultiCheckSelectorComponent,
    NotificationErrorModalComponent,
    NotificationMarkdownModalComponent,
    NotificationMessageModalComponent,
    PanelArrowComponent,
    PhoneNumberPipe,
    PhoneNumberValidator,
    StreetNumberValidator,
    TextHighlightPipe,
    TooltipComponent,
    YesNoPipe,
    MaxLengthValidator,
    SearchFilterComponent,
    HumanReadablePipe,
    TruncatePipe,
    DateRangePickerComponent,
    RemoveNullFromArrayPipe,
    PaginatorComponent,
    AccountingPipe,
    FormErrorsComponent,
    SubmissionsListBaseComponent,
    TableSortDirective,
    DropdownComponent,
    MultiCheckSearchSelectorComponent,
    AutocompleteDropdownComboComponent,
    BooleanWithFollowUpComponent,
    TrimDirective,
    DisableNumberScrollDirective
  ],
  exports: [
    AbbreviateNumberPipe,
    AddressFieldComponent,
    AutofocusDirective,
    BreakOnSlashPipe,
    CheckboxControlComponent,
    DatePickerComponent,
    DebounceDirective,
    DefaultValueDirective,
    DisplayComponent,
    EinSsnPipe,
    EmailValidator,
    FilterListComponent,
    FormsModule,
    GetGroupResourcePipe,
    GetResourcePipe,
    HasbroChoiceComponent,
    InputValidationComponent,
    IntegerDirective,
    MarkdownModule,
    MaxValueValidator,
    MinValueValidator,
    ModalSpinnerComponent,
    MultiCheckSelectorComponent,
    PanelArrowComponent,
    PhoneNumberPipe,
    PhoneNumberValidator,
    TextHighlightPipe,
    TooltipComponent,
    YesNoPipe,
    MaxLengthValidator,
    SearchFilterComponent,
    HumanReadablePipe,
    TruncatePipe,
    DateRangePickerComponent,
    RemoveNullFromArrayPipe,
    PaginatorComponent,
    AccountingPipe,
    FormErrorsComponent,
    TableSortDirective,
    DropdownComponent,
    AutocompleteDropdownComboComponent,
    BooleanWithFollowUpComponent,
    TrimDirective,
    DisableNumberScrollDirective
  ],
  providers: [
    AbbreviateNumberPipe,
    AuthGuard,
    TruncatePipe,
    GeoService,
    GooglePlacesApiFacadeService,
    HttpClient,
    LowerCaseUrlSerializer,
    NotificationService,
    ODataQueryBuilderService,
    PhoneNumberPipe,
    EinSsnPipe,
    AimService,
    RouterStateService,
    SaneDateParserFormatter,
    SuppressReloadService,
    WindowService,
    InsureZoneService,
    PlatformService,
    SessionService,
    EnvironmentService,
    ReactiveFormValidationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: TitleStrategy, useClass: UrlBasedTitleStrategy },
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ResourceService,
        ProfileService,
        RetailerProfileService,
        AimService,
        DirectoryService,
        ModalSpinnerService,
        UserService,
        DownloadService,
        AimUserProfileService
      ]
    };
  }
}