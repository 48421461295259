import { Injectable } from '@angular/core';
import { Resource } from '@core/index';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PageLayoutService {
  private headerUpdateSource: Subject<PageHeaderOptions>;
  headerUpdate: Observable<PageHeaderOptions>;

  constructor() {
    this.headerUpdateSource = new Subject<PageHeaderOptions>();
    this.headerUpdate = this.headerUpdateSource.asObservable();
  }

  updatePageHeader(options: PageHeaderOptions) {
    if (Object.isDefined(options)) {
      // https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
      setTimeout(() => this.headerUpdateSource.next(options));
    }
  }
}

export interface PageHeaderOptions {
  pageTitle?: string | Resource;
  pageSubtitle?: string | Resource;
  hiddenInMobile?: boolean;
  showNavMenu?: boolean;
}
