<div class="d-flex flex-column align-items-center page-container" [ngClass]="{'small-screen': removeMinWidth}">

  <div class="container-fluid impersonation-content px-0" *ngIf="profile?.isImpersonated">
    <div class="row mx-auto max-width">
      <div class="col">
        <impersonation-header class="w-100" [profile]="profile"></impersonation-header>
      </div>
    </div>
  </div>

  <div class="container-fluid header-content" [ngClass]="{ '' : hiddenInMobile }">
    <div class="row mx-auto" [class.max-width]="!fullWidthHeader">
      <div class="col d-flex justify-content-between align-items-center">
        <a [routerLink]="homeRoute">
          <img src="assets/images/rps-logos/RPS_logoSM_Vertical-RGB.png" />
        </a>

        <div *ngIf="showTitle" class="header-title text-center d-none d-md-block">
          {{ pageTitle | getresource }}
          <span *ngIf="showSubtitle"> &ndash; {{ pageSubtitle | getresource }}</span>
        </div>

        <div class="flex-column header-logout">
          <div *ngIf="showFirstLastNames" class="header-name">
            Hi, {{ firstName }} {{ lastName }}
          </div>
          <div *ngIf="showUsername" class="header-name">Hi, {{ username }}</div>
          <div *ngIf="showSignOutUrl">
            <a [href]="environment.portalApiUri + 'Account/SignOut'" id="logout-link">Log Out</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid navbar-content px-0">
    <div class="row mx-auto" [class.max-width]="!fullWidthMenu">
      <div class="col">
        <div class="nav-content-width" *ngIf="showNavMenu">
          <ng-content select="[nav-menu-content]"></ng-content>
        </div>
      </div>
    </div>
  </div>

  <div class="page-content-width d-flex flex-column" [class.max-width]="!fullWidthContent">
    <ng-content></ng-content>
  </div>

  <page-footer class="footer"></page-footer>
</div>