<div class="justify-content-center">
  <div class="faux-material-form-group p-0" [id]="name + '-toggle-container'" validateInput>
    <label [for]="name" [id]="name + '-toggle-label'" class="admin-label form-label d-inline"
           [ngClass]="{'required': required}">
      {{ label }}
    </label>
    <div class="float-end">
      <input type="radio" [name]="name + '-toggle'" [id]="name + '-toggle'" [value]="false" [required]="required"
             [(ngModel)]="showQuestion" (ngModelChange)="handleInputChange()" />
      No
      <input type="radio" [name]="name + '-toggle'" [id]="name + '-toggle'" [value]="true" [required]="required"
             [(ngModel)]="showQuestion" (ngModelChange)="handleInputChange()" />
      Yes
    </div>
  </div>
</div>
<div class="justify-content-center" *ngIf="showQuestion">
  <div class="faux-material-form-group row w-100 m-0" [id]="name + '-container'" validateInput>
    <label [for]="name" [id]="name + '-label'" class="admin-label form-label col-xl-6 col-12"
           [ngClass]="{'required': required}">
      {{ followUpLabel }}:
    </label>
    <div class="col-xl-6 col-12 align-content-center">
      <input type="text" [name]="name" [id]="name" [required]="showQuestion" [(ngModel)]="followUpValue"
             class="form-control" (ngModelChange)="handleInputChange()" />
    </div>
  </div>
</div>