import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { KeyNamePair } from '@app/core/aim';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Subject, debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs';

@Component({
  selector: 'autocomplete-dropdown-combo',
  templateUrl: './autocomplete-dropdown-combo.component.html',
  styleUrls: ['./autocomplete-dropdown-combo.component.scss']
})
export class AutocompleteDropdownComboComponent implements OnInit {
  @Input() placeholder = 'Enter search term';
  @Input() items: KeyNamePair[];
  @Input() showHints = true;
  @Input() value: KeyNamePair;
  @Input() disabled: boolean;
  @Output() selectedItem = new Subject<KeyNamePair>();

  // Formats the text in the dropdown (office code and name)
  @Input() formatDropdownItem = (item: KeyNamePair) => `${item.id.toUpperCase()}: ${item.name}`;
  protected filteredItems: KeyNamePair[];
  @ViewChild('comboBox', { static: true }) instance: NgbTypeahead;
  click$ = new Subject<string>(); // Open the autocomplete dropdown on dropdown button click

  constructor(
    private config: NgbTypeaheadConfig
  ) { }

  ngOnInit(): void {
    this.config.showHint = this.showHints;
  }

  // Formats the text in the form input (just office name)
  formatInputItem = (item: KeyNamePair) => item.name;

  // Overrides the default ng-autocomplete code to allow for multi-field searching as well as a click-to-activate (dropdown) button
  search: OperatorFunction<string, readonly KeyNamePair[]> = (text$) => {
    // Handle autocomplete
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term): KeyNamePair[] => {
        if (term.length < 2) return []; // Ignore until 2 chars typed
        this.filteredItems = this.items.filter(item =>
          item.id.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          item.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        );
        return this.filteredItems;
      }),
    );

    // Handle button click
    const clickedDropdownButton$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
      map((): KeyNamePair[] => this.items),
    );

    return merge(debouncedText$, clickedDropdownButton$);
  };
}
