import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService, Resource, ResourceConverter, UserProfile } from '@core/index';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { PageHeaderOptions, PageLayoutService } from '../page-layout.service';

@Component({
  selector: 'page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit, OnDestroy {
  private svcSub: Subscription;
  pageTitle: string | Resource;
  pageSubtitle: string | Resource;
  hiddenInMobile: boolean;
  firstName: string;
  lastName: string;
  username: string;
  showNavMenu = true;
  profile: UserProfile = null;
  environment = environment;

  // full width for content area excludes header and footer
  @Input() fullWidthContent = false;
  @Input() fullWidthHeader = false;
  @Input() fullWidthMenu = false;
  @Input() removeMinWidth = false; // In the small screen, get rid of the min-width. Currently trying out in producer section.
  @Input() homeRoute: string;

  constructor(
    private readonly layoutSvc: PageLayoutService,
    private readonly profileService: ProfileService,
  ) {
    this.svcSub = this.layoutSvc.headerUpdate.subscribe(this.handleHeaderUpdate.bind(this));
  }

  ngOnInit() {

    this.profileService.getProfile()
      .then(profile => {
        this.profile = profile;
        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
        this.username = profile.username;
      })
      .catch(() => {
        this.firstName = null;
        this.lastName = null;
        this.username = null;
      });
  }

  ngOnDestroy() {
    this.svcSub.unsubscribe();
  }

  get showTitle() {
    return ResourceConverter.hasTextOrKey(this.pageTitle);
  }

  get showSubtitle() {
    return this.showTitle && ResourceConverter.hasTextOrKey(this.pageSubtitle);
  }

  get showUsername() {
    return String.isUndefinedOrEmpty(this.firstName) && String.isNotEmpty(this.username);
  }

  get showFirstLastNames() {
    return String.isNotEmpty(this.firstName) && String.isNotEmpty(this.lastName);
  }

  get showSignOutUrl() {
    return this.showUsername || this.showFirstLastNames;
  }

  private handleHeaderUpdate(options: PageHeaderOptions) {
    if (Object.isDefined(options.pageTitle)) {
      this.pageTitle = options.pageTitle || '';
    }
    if (Object.isDefined(options.pageSubtitle)) {
      this.pageSubtitle = options.pageSubtitle || '';
    }
    this.hiddenInMobile = Object.isUndefined(options.hiddenInMobile) || options.hiddenInMobile;
    this.showNavMenu = Object.isUndefined(options.showNavMenu) || options.showNavMenu;
  }
}
