<div class="container rps-footer-main rps-inner-width" id="footer-main">
  <div class="row" id="footer-logo">
    <div class="col-7 col-md-4 col-lg-3">
      <page-footer-logo></page-footer-logo>
    </div>
  </div>
  <div class="row" id="text-resources">
    <ul class="col-sm-12 col-md-6 col-lg-3 rps-footer-col">
      <li><a href="https://rpsins.com/quote-and-pay/get-a-quote/">Get a Quote</a>
      </li>
      <li><a href="https://rpsins.com/quote-and-pay/make-a-payment/">Make a Payment</a>
      </li>
      <li><a href="https://rpsins.com/quote-and-pay/file-a-claim/">File a Claim</a>
      </li>
    </ul>
    <ul class="col-sm-12 col-md-6 col-lg-3 rps-footer-col">
      <li><a href="https://rpsins.com/industries/construction-environmental-insurance/">Construction &amp;
          Environmental</a>
      </li>
      <li><a href="https://rpsins.com/industries/education-insurance/">Education</a>
      </li>
      <li><a href="https://rpsins.com/industries/healthcare-insurance/">Healthcare</a>
      </li>
      <li><a href="https://rpsins.com/industries/transportation-insurance/">Transportation</a>
      </li>
    </ul>
    <ul class="col-sm-12 col-md-6 col-lg-3 rps-footer-col">
      <li><a href="https://rpsins.com/products-and-programs/casualty-insurance/">Casualty</a>
      </li>
      <li><a href="https://rpsins.com/products-and-programs/cyber-insurance/">Cyber Insurance</a>
      </li>
      <li><a href="https://rpsins.com/products-and-programs/management-liability/">Management Liability</a>
      </li>
      <li><a href="https://rpsins.com/products-and-programs/property-insurance-programs/">Property</a>
      </li>
    </ul>
    <ul class="col-sm-12 col-md-6 col-lg-3 rps-footer-col">
      <li><a href="https://rpsins.com/products-and-programs/signature-programs/">RPS Signature Programs</a>
      </li>
      <li><a href="https://rpsins.com/privacy-policy/">Privacy Policy</a></li>
      <li><a href="https://rpsins.com/terms-of-use/">Terms of Use</a></li>
      <li><a href="https://rpsins.com/legal-information/">Legal Information</a></li>
    </ul>
  </div>
  <div class="row" id="footer-divider">
    <div class="col-12">
      <hr class="rps-footer-line" role="presentation" />
    </div>
  </div>
  <div class="row rps-footer-bottom" id="footer-bottom">
    <div class="col-12 col-md-6">
      <div class="rps-footer-copy">
        <p class="copyright mb-1 pt-2">Copyright {{ year }} Risk Placement Services, Inc. All Rights Reserved.</p>
        <!-- <p class="cookie-policy">
                    <a href="https://policy.cookiereports.com/d98d36c8-en-gb.html" title="Cookie Policy" class="rps-cookie-policy">{{ 'page-layout.page-footer.cookie-policy' | getresource}}</a>
                </p> -->
      </div>
    </div>
    <div class="col-12 col-md-6">
      <page-footer-social-media></page-footer-social-media>
    </div>
  </div>
</div>