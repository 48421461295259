<div class="page-container">
  <div class="content-container">
    <div class="banner-image" backgroundImage></div>
    <div class="content-box mx-auto">
      <div class="header text-center">
        <img class="w-75" src="assets/images/rps-logos/RPS_logoSM_Vertical-RGB.png" alt="RPS - Risk Placement Services">
      </div>
      <div class="content p-3">
        <ng-content></ng-content>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>