import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService, UserProfile, UserType, WindowService } from '@app/core';
import { PlatformService } from '@app/core/platform-service';
import { DirectoryService } from '@core/index';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class HomeRedirectService {

  constructor(
    private readonly router: Router,
    private readonly profile: ProfileService,
    private readonly windowService: WindowService,
    private readonly directoryService: DirectoryService,
    private readonly platformService: PlatformService) {

  }

  // convenience function
  isLocalhost() {
    return this.windowService.isLocalhost();
  }

  // convenience function
  activeUser() {
    return this.profile.getProfile();
  }

  async redirect(profile: UserProfile = null) {
    if (!profile) {
      profile = await this.profile.getProfile();
    }

    // If there is a user preference for the homepage, redirect to there
    let preferences = this.directoryService.getPreferences();

    if (preferences?.home != null) {
      let urlString = preferences.home.toString();

      // This has to be a local path if theres no http prefix
      if (urlString.indexOf('http://') === -1 && urlString.indexOf('https://') === -1) {
        this.router.navigateByUrl(urlString);
      }

      // Check if an absolute url is still a local path and trim it to use angular routing
      let inspectResult = this.platformService.inspectAndTransformUrl(urlString);

      // Route to external path
      if (inspectResult.openInNewWindow) {
        this.windowService.nativeWindow.location.assign(inspectResult.redirectUrl);
      }
      // Route to local path
      else {
        this.router.navigateByUrl(inspectResult.redirectUrl);
      }
    }

    // Otherwise redirect based on type of user
    else {
      if (this.windowService.isLocalhost()) {
        this.router.navigateByUrl('/');
      } else if (profile.userType == UserType.Internal) {
        if (profile.roles.includes('RampAdmin')) {
          this.windowService.nativeWindow.location.assign(environment.rampAdminHomeUri);
        } else if (profile.isAccountExec) {
          this.router.navigateByUrl('/producer/home');
        } else {
          // let portal deal with them
          this.windowService.nativeWindow.location.assign(environment.myrpsUri + '/portal/account');
        }
      } else if (profile.permissions.includes('restricted-user')) {
        this.router.navigateByUrl('/onboarding/resume');
      } else {
        // assume external
        this.router.navigateByUrl('/retailer/home');
      }
    }
  }
}