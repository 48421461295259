import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { random } from 'lodash';
import { CookieService } from 'ngx-cookie-service';

@Directive({
  selector: '[backgroundImage]'
})
export class BackgroundImageDirective implements OnInit {

  private readonly CookieName = 'idsrv.bg';

  // keys and images must match the background cookie set by security
  private backgroundImages = [
    { key: 'b1', path: 'assets/images/backgrounds/city-night.jpg' },
    { key: 'b2', path: 'assets/images/backgrounds/small-town.jpg' },
    { key: 'b3', path: 'assets/images/backgrounds/hot-air-balloons.jpg' },
    { key: 'b4', path: 'assets/images/backgrounds/four-wheelers.jpg' },
    { key: 'b5', path: 'assets/images/backgrounds/windmills.jpg' },
    { key: 'b6', path: 'assets/images/backgrounds/tropical-cityscape.jpg' }
  ];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private readonly cookies: CookieService
  ) { }

  ngOnInit(): void {
    if (!this.reuseSecurityBg()) {
      this.setRandomBg();
    }
  }

  private reuseSecurityBg() {
    const bg = this.cookies.get(this.CookieName);
    if (bg) {
      const idx = this.backgroundImages.findIndex(x => x.key == bg);
      if (idx >= 0) {
        this.setBg(this.backgroundImages[idx].path);
        return true;
      }
    }
    return false;
  }

  private setRandomBg() {
    const background = this.backgroundImages[random(0, this.backgroundImages.length - 1)];
    this.setBg(background.path);
    const expiresAt = new Date();
    expiresAt.setHours(expiresAt.getHours() + 8);
    this.cookies.set(this.CookieName, background.key, expiresAt, '/');
  }

  private setBg(path: string) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url('${path}')`);
  }
}
