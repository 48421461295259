import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Debug, DirectoryService, NotificationService, ToastType, WindowService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'credential-modal',
  templateUrl: './credential-modal.component.html'
})
export class CredentialModalComponent {

  username: string;
  password: string;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly directory: DirectoryService,
    private readonly http: HttpClient,
    private readonly window: WindowService,
    private readonly notificationSvc: NotificationService) { }

  async endImpersonation() {
    if (String.isUndefinedOrEmpty(this.username) || String.isUndefinedOrEmpty(this.password)) {
      return;
    }

    const loginInfo = { username: this.username, password: this.password };
    const endpoints = this.directory.getDefinition('impersonation', '^1.0.0');

    try {
      await firstValueFrom(this.http.post(endpoints['end-endpoint'], loginInfo));
      this.window.nativeWindow.location.href = endpoints['end-redirect'];
    }
    catch (e) {
      Debug.error('End impersonate failure', e);
      this.notificationSvc.showToast('Unable to end impersonation', 'Impersonation Error', ToastType.Error);
    }

    this.modal.close();
  }

  cancel() {
    this.modal.close();
  }
}
