import { Component } from '@angular/core';
import { WindowService } from '@core/index';

@Component({
  selector: 'page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {

  constructor(private readonly windowService: WindowService) { }

  scrollTop() {
    const document = this.windowService.nativeWindow.document;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  get year() {
    return new Date().getFullYear();
  }
}
