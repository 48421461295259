import { Component } from '@angular/core';
import { WindowService } from '@app/core';
import { environment } from '@env';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sso-modal',
  templateUrl: './sso-modal.component.html'
})
export class SsoModalComponent {

  constructor(private readonly modal: NgbActiveModal,
    private readonly window: WindowService) { }

  cancel() {
    this.modal.close();
  }

  end() {
    let url = String.trimCharEnd(environment.portalApiUri, '/') + '/authentication/endimpersonate';
    this.window.nativeWindow.location.href = url;
  }
}
